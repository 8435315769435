import {
  memo,
  useMemo,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { InputUi } from './InputUi';
import { InputFormikProps } from './types';

export const InputFormik: FC<InputFormikProps> = memo(({
  name,
  debounceInterval = 100,
  checkTouched = true,
  showError = true,
  isInvalid: isInvalidProps = false,
  onChange: onChangeProps = () => {},
  onBlur: onBlurProps = () => {},
  ...props
}) => {
  const [, { value, error, touched }, { setValue, setTouched }] = useField(name);
  const [localValue, setLocalValue] = useState(value);
  const isInvalid: boolean = useMemo(
    () => !!(error && (!checkTouched || touched)) || !!isInvalidProps,
    [error, touched, checkTouched, isInvalidProps],
  );
  const setFormValue = useCallback((val: any) => { // todo
    setValue(val);
    onChangeProps(val);
  }, [onChangeProps, setValue]);
  const debouncedCallback = useDebouncedCallback(setFormValue, debounceInterval, { leading: true });
  const onChange = useCallback((val: number | string) => {
    setLocalValue(val);
    debouncedCallback(val);
  }, [debouncedCallback]);
  const onBlur = useCallback(() => {
    setTouched(true);
    onBlurProps();
  }, [onBlurProps, setTouched]);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <InputUi
      {...props}
      name={name}
      value={localValue}
      error={error}
      showError={showError}
      isInvalid={isInvalid}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

export default InputFormik;
