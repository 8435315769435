import { ReactNode, SyntheticEvent } from 'react';
import { Theme } from 'uikitv2/common/types';

export type VariantNotification = 'yellow' | 'red';

export interface InputUiProps {
  'data-testid'?: string;
  id?: string;
  value?: number | string;
  placeholder?: string;
  label?: string;
  tooltip?: string | ReactNode;
  prepend?: (props: { focused: boolean }) => string | ReactNode;
  append?: (props: { focused: boolean }) => string | ReactNode;
  disabled?: boolean;
  classNameWrap?: string;
  classNameLabel?: string;
  classNameInput?: string;
  classNamePrepend?: string;
  classNameAppend?: string;
  classNameErrorEmpty?: string;
  classNameError?: string;
  classNameInputLabelFocused?: string;
  classNameInputLabel?: string;
  error?: string;
  min?: number;
  max?: number;
  name?: string;
  isInvalid?: boolean;
  autoComplete?: string;
  isNumber?: boolean;
  showError?: boolean;
  isFloat?: boolean;
  isPositive?: boolean;
  isDate?: boolean;
  onChange?: Function;
  onKeyDown?: (e: SyntheticEvent) => void;
  onBlur?: Function;
  onFocus?: Function;
  loading?: boolean;
  as?: 'textarea' | 'input';
  resize?: 'none'
    | 'inherit'
    | '-moz-initial'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'block'
    | 'both'
    | 'horizontal'
    | 'inline'
    | 'vertical';
  autosize?: boolean;
  mask?: string;
  alwaysShowMask?: boolean;
  beforeMaskedStateChange?: Function;
  renderError?: (error: any) => ReactNode;
  markLabelError?: boolean;
  maxRows?: number;
  minRows?: number;
  emptyError?: boolean;
  theme?: Theme;
  readOnly?: boolean;
  type?: HTMLInputElement['type'];
  description?: string;
  variantNotification?: VariantNotification;
}

export enum InputUiTypes {
  REGULAR,
  PHONE,
}

export interface InputFormikProps extends InputUiProps {
  name: string;
  debounceInterval?: number;
  checkTouched?: boolean;
  inputUiType?: InputUiTypes;
  onChange?: Function;
  isInvalid?: boolean;
}

export interface InputWithResetProps {
  onChange?: Function;
}

export enum keyCodes {
  ARROW_UP = 38,
  ARROW_DOWN = 40,
}

export interface InputPhoneProps extends InputUiProps {}

export type Val = string | number;
